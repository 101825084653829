import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('documentTemplate', 'documentTemplates');

const customActions = {
  async LOAD_documentTemplates({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('documentTemplates')
      .query({ $filter: 'IsDeleted eq false', $expand: 'Product' });
    commit('SET_documentTemplates', data);

    return data;
  },
  async LOAD_documentTemplatesByProduct({ commit, rootGetters }, productId) {
    const data = await odata.o(rootGetters)
      .get('documentTemplates')
      .query({ $filter: `IsDeleted eq false AND ProductId eq ${productId}` });
    commit('SET_documentTemplates', data);

    return data;
  },
};

export default { ...actions, ...customActions };
