import axios from 'axios';

export default {
  async LOAD_claims({ commit }) {
    const { data } = await axios.get('/api/me');

    commit('SET_claims', data.claims);
  },
  LOGOUT({ commit }) {
    commit('SET_username', '');
    commit('SET_token', '');
    commit('SET_roles', undefined);
  },
};
