import { odata } from '../../conf';
import Actions from '../../odata/actionsForStringId';

const actions = new Actions('supplier', 'suppliers', 'code');

const customActions = {
  async LOAD_suppliers({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('suppliers')
      .query({ $filter: 'IsDeleted eq false', $orderby: 'Name' });
    commit('SET_suppliers', data);

    return data;
  },
};

export default { ...actions, ...customActions };
