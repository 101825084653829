<template>
  <v-btn class="mt-2" icon title="Download upload template" @click="download">
    <v-icon>{{ mdiCloud }}</v-icon>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';
import { Client } from '@codehq/aurora-net';
import { mdiCloud } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'DownloadTemplate',
  mixins: [loading],
  props: {
    entitySet: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiCloud,
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    client() {
      return new Client({
        baseUrl: process.env.VUE_APP_APIURL,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        apiClient: 'axios',
        timeout: 30000,
        headers: {
          organizationid: this.organization.id,
        },
      });
    },
  },
  methods: {
    async download() {
      const downloadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/template`;
      try {
        const response = await this.client.get(downloadUrl, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.entitySet}_template.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Failed to download template');
      }
    },
  },
};
</script>
