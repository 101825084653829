<template>
  <v-btn class="mt-2" icon title="Reset all the data" :loading="isLoading" @click="reset">
    <v-icon>{{ mdiLockReset }}</v-icon>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';
import { Client } from '@codehq/aurora-net';
import { mdiLockReset } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ResetData',
  mixins: [loading],
  props: {
    entitySet: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiLockReset,
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    client() {
      return new Client({
        baseUrl: process.env.VUE_APP_APIURL,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        apiClient: 'axios',
        timeout: 30000,
        headers: {
          organizationid: this.organization.id,
        },
      });
    },
  },
  methods: {
    async reset() {
      const result = await this.$confirm('Are you sure you want to reset the data?');
      if (!result) {
        return;
      }
      try {
        this.isLoading = true;
        const downloadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/reset`;
        await this.client.post(downloadUrl);
        this.$emit('refresh');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Failed to reset data');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
