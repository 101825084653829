import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('playBook', 'playBooks');

const customActions = {
  async START_playBook({ rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .post(`playBooks(${id})/start`)
      .query();

    return data;
  },
};

export default { ...actions, ...customActions };
