import 'core-js/stable';
import '@/styles/styles.scss';

import axios from 'axios';
import auroraModule from '@codehq/aurora-app-core/src/plugins/auroraModule';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import { Auth, AuthPlugin } from '@codehq/aurora-auth';
import { Client, ClientPlugin } from '@codehq/aurora-net';
import { mdiAlert } from '@mdi/js';
import VuetifyConfirmPlugin from 'vuetify-confirm';
import VueNumeric from 'vue-numeric';

// import VueMask from 'vue-mask';
import Vue from 'vue';
import App from './App.vue';
import ImageUpload from './components/ImageUpload.vue';
import FileUploadSelector from './components/FileUploadSelector.vue';
import KeyTextField from './components/KeyTextField.vue';
import MultipleAutoSelect from './components/MultipleAutoSelect.vue';
import MultipleTextFields from './components/MultipleTextFields.vue';
import ClipboardTextField from './components/ClipboardTextField.vue';
import SpanViewMore from './components/SpanViewMore.vue';
import ServerDataTable from './components/DataTables/ServerDataTable.vue';
import SuccessButton from './components/SuccessButton.vue';
import DatePicker from './components/DatePicker.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { i18n } from './i18n';

Vue.config.productionTip = false;

Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL,
  store,
});

Vue.use(VuetifyConfirmPlugin, {
  vuetify,
  buttonTrueText: 'Yes',
  buttonFalseText: 'No',
  color: 'warning',
  icon: mdiAlert,
  title: 'Warning',
  width: 350,
  property: '$confirm',
});

// register global components
Vue.use(AuthPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
Vue.use(ClientPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 60000,
});

// Vue.use(VueMask);
Vue.component(ImageUpload.name, ImageUpload);
Vue.component(FileUploadSelector.name, FileUploadSelector);
Vue.component(KeyTextField.name, KeyTextField);
Vue.component(ClipboardTextField.name, ClipboardTextField);
Vue.component(MultipleAutoSelect.name, MultipleAutoSelect);
Vue.component(MultipleTextFields.name, MultipleTextFields);
Vue.component(ServerDataTable.name, ServerDataTable);
Vue.component(SpanViewMore.name, SpanViewMore);
Vue.component(DatePicker.name, DatePicker);
Vue.component(ProgressLinearTimeout.name, ProgressLinearTimeout);
Vue.component('SuccessButton', SuccessButton);

Vue.use(VueNumeric);

// setup store
const client = new Client({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 60000,
});
store.$http = client;

const auth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
store.$auth = auth;

// Setup API
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
