<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <ToastError />
    <ToastNotify />
  </component>
</template>

<script>
import { computed } from 'vue';
import { useRouter, useStore } from '@/use';

const LayoutBlank = () => import(/* webpackChunkName: "layout-Blank" */'@/layouts/Blank.vue');
const LayoutContent = () => import(/* webpackChunkName: "layout-Content" */'@/layouts/Content.vue');
const LayoutUpdate = () => import(/* webpackChunkName: "layout-Update" */'@/layouts/Update.vue');
const ToastError = () => import(/* webpackChunkName: "components-ToastError" */'@/components/ToastError.vue');
const ToastNotify = () => import(/* webpackChunkName: "components-ToastNotify" */'@/components/ToastNotify.vue');

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutUpdate,
    ToastError,
    ToastNotify,
  },
  setup() {
    const { route } = useRouter();

    const store = useStore();

    store.dispatch('auth/INIT_auth', {
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      url: process.env.VUE_APP_APIURL,
    });

    const resolveLayout = computed(() => {
      if (process.env.VUE_APP_APPMAINTENANCE === 'true') return 'layout-update';

      // Handles initial route
      if (route.value.name === null) return null;

      if (route.value.meta.layout === 'blank') return 'layout-blank';

      return 'layout-content';
    });

    return {
      resolveLayout,
    };
  },
};
</script>
