<template>
  <div>
    <FileUploadSelector
      label="Select a file to upload"
      accept="image/*"
      @input="fileSelected"
    />
    <v-card
      data-cy="File-Upload"
      :class="{ 'grey lighten-2': dragover }"
      color="grey lighten-2"
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
    >
      <v-card-text>
      </v-card-text>
      <v-card-text>
        <v-row
          class="d-flex flex-column"
          dense
          align="center"
          justify="center"
        >
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          />
          <v-icon
            v-else
            color="black"
            :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
            size="60"
          >
            {{ mdiCloudUpload }}
          </v-icon>
          <p class="black--text">
            {{ label }}
          </p>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiArrowUpBoldCircleOutline, mdiCloudUpload } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FileUploadSelector from './FileUploadSelector.vue';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'ImageUpload',
  components: {
    FileUploadSelector,
  },
  mixins: [loading],
  props: {
    /** Set the Mimetype accept. Defaults to imaage/*.
    */
    accept: {
      type: String,
      default: 'image/*',
    },

    /** Error message for max file size. Defaults to File is larger than the limit of 20Mb.
    */
    errorFileSize: {
      type: String,
      default: 'File is larger than the limit of 20Mb',
    },

    /** Set the icon. Defaults to mdi-camera.
    */
    icon: {
      type: String,
      default: 'mdi-camera',
    },

    /** Set the label. Defaults to image.
    */
    label: {
      type: String,
      default: 'Drop your files here',
    },

    /** Max file size. Defaults to 20000000 (20Mb).
    */
    maxFileSize: {
      type: Number,
      default: 20000000,
    },
  },
  data() {
    return {
      mdiArrowUpBoldCircleOutline,
      mdiCloudUpload,
      errors: [],
      file: null,
      dragover: false,
      uploadedFiles: [],
    };
  },
  methods: {
    ...mapActions('userFiles', ['CREATE_userFile']),
    fileChanged(file) {
      this.errors = [];
      if (file?.size > this.maxFileSize) {
        this.errors.push(this.errorFileSize);
      }
    },
    async upload() {
      const { file } = this;
      this.isLoading = true;
      const result = await this.CREATE_userFile(file);
      const url = `${baseUrl}/auroraApi/files/${result.id}`;
      this.$emit('input', {
        uploadedFile: result,
        url,
      });
      this.$emit('url', url);
      this.isLoading = false;
      this.file = undefined;
    },
    async fileSelected(file) {
      this.isLoading = true;
      const result = await this.CREATE_userFile(file);
      const url = `${baseUrl}/auroraApi/files/${result.id}`;
      this.$emit('input', {
        uploadedFile: result,
        url,
      });
      this.$emit('url', url);
      this.isLoading = false;
      this.$root.$emit('toast:notify', 'File uploaded successfully');
    },
    async onDrop(e) {
      this.dragover = false;
      this.isLoading = true;
      const result = await this.CREATE_userFile(e.dataTransfer.files[0]);
      const url = `${baseUrl}/auroraApi/files/${result.id}`;
      this.$emit('input', {
        uploadedFile: result,
        url,
      });
      this.$emit('url', url);
      this.isLoading = false;
      this.dragover = true;
      this.$root.$emit('toast:notify', 'File uploaded successfully');
    },
  },
};
</script>
