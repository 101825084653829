<template>
  <div>
    <v-btn class="mt-2" icon title="Upload data from Excel" :loading="isLoading" @click="openFileDialog">
      <v-icon>{{ mdiDatabaseImport }}</v-icon>
    </v-btn>
    <input ref="fileInput" type="file" style="display:none" @input="upload">
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Client } from '@codehq/aurora-net';
import { mdiDatabaseImport } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'UploadData',
  mixins: [loading],
  props: {
    entitySet: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiDatabaseImport,
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    ...mapState('auth', ['token']),
    client() {
      return new Client({
        baseUrl: process.env.VUE_APP_APIURL,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        apiClient: 'axios',
        timeout: -1,
        headers: {
          organizationid: this.organization.id,
        },
      });
    },
  },
  methods: {
    async openFileDialog() {
      this.$refs.fileInput.click();
    },
    async upload(event) {
      const file = event.target.files[0];
      const uploadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/upload`;
      const formData = new FormData();
      formData.append('file', file);
      try {
        this.isLoading = true;
        const { client } = this;
        client.headers['Content-Type'] = 'multipart/form-data';
        await client.post(uploadUrl, formData);
        this.$emit('uploaded');
        this.$root.$emit('toast:notify', 'Data uploaded successfully');
      } catch (error) {
        this.$log.error(error);
        if (error.response.status === 400) {
          const { message } = error.response.data.error;
          const errorMessage = JSON.parse(message);
          const errorNotification = errorMessage
            .map((e) => `Error importing row ${e.Row}: ${e.Message}`)
            .join('\r\n');
          this.$root.$emit('toast:error', `${errorNotification}`);
        } else {
          this.$root.$emit('toast:error', 'Error uploading data');
        }
      } finally {
        this.isLoading = false;
        this.$refs.fileInput.value = '';
      }
    },
    async download() {
      const downloadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/download`;
      try {
        const response = await this.client.get(downloadUrl, { responseType: 'blob' });
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.name}.xlsx`;
        link.click();
      } catch (error) {
        this.$log.error(error);
      }
    },
  },
};
</script>
