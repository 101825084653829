import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('productPurchasePrice', 'productPurchasePrices');

const customActions = {
  async LOAD_productPurchasePrices({ commit, rootGetters }, productId) {
    const data = await odata.o(rootGetters)
      .get('productPurchasePrices')
      .query({
        $filter: `IsDeleted eq false AND ProductId eq ${productId}`,
        $orderby: 'Created desc',
      });
    commit('SET_productPurchasePrices', data);

    if (data.length > 0) {
      return data[0];
    }

    return null;
  },
};

export default { ...actions, ...customActions };
