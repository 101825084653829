import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('productFeature', 'productFeatures');

const customActions = {
  async LOAD_productFeatures({ commit, rootGetters }, productId) {
    const data = await odata.o(rootGetters)
      .get('productFeatures')
      .query({ $filter: `IsDeleted eq false AND ProductId eq ${productId}` });
    commit('SET_productFeatures', data);

    return data;
  },
};

export default { ...actions, ...customActions };
