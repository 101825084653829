import { getCurrentInstance } from 'vue';

export * from './useAuth';
export * from './useForm';
export * from './useLogging';
export * from './useStore';
export * from './useRoot';
export * from './useRouter';
export * from './useFormatting';

export const useEmit = () => {
  const vm = getCurrentInstance().proxy;

  return vm.$root.$emit;
};

export const _ = null;
