import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('productImage', 'productImages');

const customActions = {
  async LOAD_productImages({ commit, rootGetters }, productId) {
    commit('SET_productImages', []);
    const data = await odata.o(rootGetters)
      .get('productImages')
      .query({ $filter: `IsDeleted eq false AND ProductId eq ${productId}` });
    commit('SET_productImages', data);

    return data;
  },
};

export default { ...actions, ...customActions };
