import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('productPrice', 'productPrices');

const customActions = {
  async LOAD_productPrices({ rootGetters }, { productId, country }) {
    if (productId && country) {
      const $filter = `IsDeleted eq false AND ProductId eq ${productId} AND Country eq '${country}'`;
      const data = await odata.o(rootGetters)
        .get('productPrices')
        .query({
          $filter,
          $orderby: 'Created DESC',
        });

      return data;
    }

    return [];
  },
  async LOAD_allProductPrices({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('productPrices')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Product',
        $orderby: 'Created DESC',
      });

    return data;
  },

  // async LOAD_supplierProductPrices({ rootGetters }, { productId, supplierId }) {
  //   if (productId && supplierId) {
  //     const $filter = `IsDeleted eq false AND ProductId eq ${productId} AND SupplierCode eq '${supplierId}'`;
  //     const data = await odata.o(rootGetters)
  //       .get('productPrices')
  //       .query({
  //         $filter,
  //         $orderby: 'Created DESC',
  //       });

  //     return data;
  //   }

  //   return [];
  // },
};

export default { ...actions, ...customActions };
