import axios from 'axios';
import Actions from '../../odata/actions';

const actions = new Actions('orgSetting', 'orgSettings');

const customActions = {
  async CREATE_orgSetting({ commit, state }, payload) {
    const { data } = await axios.post('auroraApi/orgSettings', payload);
    commit('SET_orgSettings', [...state.orgSettings, data]);

    return data;
  },
  async UPDATE_orgSetting({ commit, state }, payload) {
    const { data } = await axios.put(`auroraApi/orgSettings/${payload.id}`, payload);
    const orgSettings = state.orgSettings.map((orgSetting) => {
      if (orgSetting.id === payload.id) {
        return data;
      }

      return orgSetting;
    });

    commit('SET_orgSettings', orgSettings);

    return data;
  },
  async DELETE_orgSetting({ commit, state }, id) {
    await axios.delete(`auroraApi/orgSettings/${id}`);
    const orgSettings = state.orgSettings.filter((orgSetting) => orgSetting.id !== id);
    commit('SET_orgSettings', orgSettings);
  },
  async LOAD_orgSettings({ commit }) {
    const { data } = await axios.get('auroraApi/orgSettings');
    commit('SET_orgSettings', data);

    return data;
  },
};

export default { ...actions, ...customActions };
