import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('approvalRequest', 'approvalRequests');

const customActions = {
  async LOAD_approvalRequests({ commit, rootGetters }, productId) {
    const data = await odata.o(rootGetters)
      .get('approvalRequests')
      .query({
        $filter: `IsDeleted eq false AND ProductId eq ${productId}`,
        $expand: 'Responses',
      });
    commit('SET_approvalRequests', data);

    return data;
  },
};

export default { ...actions, ...customActions };
