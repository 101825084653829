export default {
  getToken: state => state.token,
  isSaving: state => state.isSaving,
  getUsername: state => state.username,
  isLoggedIn: state => state.username?.length > 0,
  isInRole: state => role => state.roles.some(r => r === role),
  countryNames: state => state.countries.map(({ name }) => name),
  hasClaim: state => (claimType, claimValue) => state.claims.some(c => c.claimType === claimType && c.claimValue === claimValue),
  sortedLanguages: state => state.languages.sort(),
};
