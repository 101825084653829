export default {
  allProductsHeader: [
    { value: 'blurb', text: 'Blurb', filterable: true },
    { value: 'boxedHeight', text: 'Boxed Height', filterable: true },
    { value: 'boxedLength', text: 'Boxed Length', filterable: true },
    { value: 'boxedWeight', text: 'Boxed Weight', filterable: true },
    { value: 'boxedWidth', text: 'Boxed Width', filterable: true },
    { value: 'brandCode', text: 'Brand', filterable: true },
    { value: 'canExpire', text: 'Can Expire', filterable: true },
    { value: 'commodityCode', text: 'Commodity Code', filterable: true },
    { value: 'contentOfBox', text: 'Content Of Box', filterable: true },
    { value: 'countryApprovedAustralia', text: 'Country Approved Australia', filterable: true },
    { value: 'countryApprovedEurope', text: 'Country Approved Europe', filterable: true },
    { value: 'countryApprovedSouthAfrica', text: 'Country Approved South Africa', filterable: true },
    { value: 'countryApprovedUnitedKingdom', text: 'Country Approved United Kingdom', filterable: true },
    { value: 'defaultSupplierCode', text: 'Default Supplier Code', filterable: true },
    { value: 'displayUnitHeight', text: 'Display Unit Height', filterable: true },
    { value: 'displayUnitLength', text: 'Display Unit Length', filterable: true },
    { value: 'displayUnitWeight', text: 'Display Unit Weight', filterable: true },
    { value: 'displayUnitWidth', text: 'Display Unit Width', filterable: true },
    { value: 'emdn', text: 'Emdn', filterable: true },
    { value: 'expiryCeCertificate', text: 'Expiry Ce Certificate', filterable: true },
    { value: 'expiryIso13485', text: 'Expiry Iso13485', filterable: true },
    { value: 'expiryIso9001', text: 'Expiry Iso9001', filterable: true },
    { value: 'gmdn', text: 'Gmdn', filterable: true },
    { value: 'gmdnDescription', text: 'Gmdn Description', filterable: true },
    { value: 'hasPricelist', text: 'Has Pricelist', filterable: true },
    { value: 'hasSignedContract', text: 'Has Signed Contract', filterable: true },
    {
      value: 'imageUrl', text: 'Product Image', filterable: false, width: '50px',
    },
    { value: 'instructionsForUse', text: 'Instructions For Use', filterable: true },
    { value: 'intendedUse', text: 'Intended Use', filterable: true },
    { value: 'isCeCertificate', text: 'Is Ce Certificate', filterable: true },
    { value: 'isHypoallergenic', text: 'Is Hypoallergenic', filterable: true },
    { value: 'isIso13485', text: 'Is Iso13485', filterable: true },
    { value: 'isIso9001', text: 'Is Iso9001', filterable: true },
    { value: 'isLatexFree', text: 'Is Latex Free', filterable: true },
    { value: 'isMedicalDevice', text: 'Is Medical Device', filterable: true },
    { value: 'isMedinoxDoc', text: 'Is Medinox Doc', filterable: true },
    { value: 'languagesOnIfu', text: 'Languages On Ifu', filterable: true },
    { value: 'languagesOnPackaging', text: 'Languages On Packaging', filterable: true },
    { value: 'layerCarton', text: 'Layer Carton', filterable: true },
    { value: 'layerHeight', text: 'Layer Height', filterable: true },
    { value: 'layerUnits', text: 'Layer Units', filterable: true },
    { value: 'longDescription', text: 'Long Description', filterable: true },
    { value: 'material', text: 'Material', filterable: true },
    { value: 'maxStackingHeight', text: 'Max Stacking Height', filterable: true },
    { value: 'medinoxDocLink', text: 'Medinox Doc Link', filterable: true },
    { value: 'model', text: 'Model', filterable: true },
    { value: 'nappiCode', text: 'Nappi Code', filterable: true },
    { value: 'palletSelection', text: 'Pallet Selection', filterable: true },
    { value: 'piecesPerBox', text: 'Pieces Per Box', filterable: true },
    { value: 'pipCode', text: 'Pip Code', filterable: true },
    { value: 'pmsStatement', text: 'Pms Statement', filterable: true },
    { value: 'pricelistImageUrl', text: 'Pricelist Image Url', filterable: true },
    { value: 'pricelistPosition', text: 'Pricelist Position', filterable: true },
    { value: 'productGroupCode', text: 'Product Group', filterable: true },
    { value: 'secondaryPackagingBarcode', text: 'Secondary Packaging Barcode', filterable: true },
    { value: 'serverLink', text: 'Server Link', filterable: true },
    { value: 'shippingCartonBarcode', text: 'Shipping Carton Barcode', filterable: true },
    { value: 'shippingCartonHeight', text: 'Shipping Carton Height', filterable: true },
    { value: 'shippingCartonLength', text: 'Shipping Carton Length', filterable: true },
    { value: 'shippingCartonWeight', text: 'Shipping Carton Weight', filterable: true },
    { value: 'shippingCartonWidth', text: 'Shipping Carton Width', filterable: true },
    { value: 'shortDescription', text: 'Short Description', filterable: true },
    { value: 'storageTempMax', text: 'Storage Temp Max', filterable: true },
    { value: 'storageTempMin', text: 'Storage Temp Min', filterable: true },
    { value: 'unboxedHeight', text: 'Unboxed Height', filterable: true },
    { value: 'unboxedLength', text: 'Unboxed Length', filterable: true },
    { value: 'unboxedWeight', text: 'Unboxed Weight', filterable: true },
    { value: 'unboxedWidth', text: 'Unboxed Width', filterable: true },
    { value: 'unitBarcode', text: 'Unit Barcode', filterable: true },
    { value: 'warning', text: 'Warning', filterable: true },
    { value: 'yearsExpiry', text: 'Years Expiry', filterable: true },
    { value: 'orgId', text: 'Org Id', filterable: true },
    { value: 'created', text: 'Created', filterable: true },
    { value: 'createdBy', text: 'Created By', filterable: true },
    { value: 'createdById', text: 'Created By Id', filterable: true },
    { value: 'isActive', text: 'Is Active', filterable: true },
    { value: 'isDeleted', text: 'Is Deleted', filterable: true },
    { value: 'updated', text: 'Updated', filterable: true },
    { value: 'updatedBy', text: 'Updated By', filterable: true },
    { value: 'updatedById', text: 'Updated By Id', filterable: true },
  ],
  baseProductsHeader: [
    {
      order: 1, value: 'imageUrl', text: 'Image Url', filterable: false, width: '50px',
    },
    {
      order: 2, value: 'model', text: 'Model', filterable: true,
    },
    {
      order: 3, value: 'shortDescription', text: 'Short Description', filterable: true,
    },
    {
      order: 4, value: 'unitBarcode', filterable: true, text: 'Unit Barcode',
    },
    {
      order: 5, value: 'actions', filterable: false, text: '',
    },
  ],
};
