<template>
  <div>
    <v-autocomplete
      v-model="localValues"
      chips
      multiple
      auto-select-first
      :label="label"
      :items="items"
      outlined
      dense
      @change="$emit('input', valueString)"
    />
  </div>
</template>

<script>
import { mdiPlus, mdiMinus } from '@mdi/js';

export default {
  name: 'MultipleAutoSelect',
  props: {
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiPlus,
      mdiMinus,
      localValues: [],
    };
  },
  computed: {
    valueString() {
      return this.localValues.join(';');
    },
  },
  watch: {
    value(newValues) {
      this.localValues = newValues.split(';');
    },
  },
  mounted() {
    if (this.value?.length > 0) {
      this.localValues = this.value.split(';');
    }
  },
  methods: {
    toggleItem(index) {
      if (index === 0) {
        this.localValues.push('');
      } else {
        this.localValues.splice(index, 1);
      }
      this.$emit('input', this.valueString);
    },
  },
};
</script>
