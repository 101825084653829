<template>
  <div data-cy="FileUpload-input">
    <v-file-input
      v-model="file"
      color="secondary"
      :label="label"
      :loading="isLoading"
      :accept="accept"
      show-size
      :prepend-icon="mdiCloudUpload"
      :error-messages="errors"
      style="min-width: 300px"
      @change="fileChanged"
    >
    </v-file-input>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiCloudUpload } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FileUploadSelector',
  mixins: [loading],
  props: {
    /** Set the Mimetype accept. Defaults to imaage/*.
    */
    accept: {
      type: String,
      default: 'image/*',
    },

    /** Error message for max file size. Defaults to File is larger than the limit of 2Mb.
    */
    errorFileSize: {
      type: String,
      default: 'File is larger than the limit of 20Mb',
    },

    /** Set the label. Defaults to image.
    */
    label: {
      type: String,
      default: 'Upload new file',
    },

    /** Max file size. Defaults to 2000000 (20Mb).
    */
    maxFileSize: {
      type: Number,
      default: 20000000,
    },
  },
  data() {
    return {
      mdiCloudUpload,
      errors: [],
      file: null,
    };
  },
  methods: {
    ...mapActions('userFiles', ['CREATE_userFile']),
    fileChanged(file) {
      this.errors = [];
      if (!file) {
        return;
      }
      if (file?.size > this.maxFileSize) {
        this.errors.push(this.errorFileSize);
      } else {
        this.$emit('input', file);
        this.file = undefined;
      }
    },
  },
};
</script>
