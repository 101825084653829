<template>
  <div style="max-height: 50vw; width: 100%;">
    {{ displayText }}
    <v-btn v-if="text.length > 100" small text @click="showAll = !showAll">
      {{ showAll ? 'Show less' : 'Show more' }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SpanViewMore',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: '',
      showAll: false,
    };
  },
  computed: {
    displayText() {
      if (this.showAll) {
        return this.text;
      }

      return this.text.length > 100 ? `${this.text.substring(0, 100)}...` : this.text;
    },
  },
  watch: {
    value(newValue) {
      this.text = newValue;
    },
  },
};
</script>
