<template>
  <v-dialog v-model="dialog" max-width="400">
    <template #activator="{ on }">
      <v-btn class="mt-2" :icon="hideLabel" :text="!hideLabel" :title="title" v-on="on">
        <v-icon>{{ icon }}</v-icon>
        <span v-if="!hideLabel" class="ml-2">{{ title }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title color="accent">
        <span class="grey--text">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot name="content" />
      </v-card-text>
      <v-card-actions>
        <slot name="actions" :dialog="dialog">
          <slot name="actions-left"></slot>
          <v-spacer></v-spacer>
          <slot name="actions-right">
            <v-btn text @click="dialog = false">
              Done
            </v-btn>
          </slot>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';

/**
 * BasicDialog component represents a basic dialog box.
 *
 * @component
 * @example
 * <BasicDialog title="Dialog Title" icon="dialog-icon">
 *   <!-- Dialog content goes here -->
 * </BasicDialog>
 */
export default {
  name: 'BasicDialog',
  props: {
    /**
     * Flag indicating whether the dialog is open or not.
     *
     * @type {Boolean}
     * @default false
     */
    hideLabel: {
      type: Boolean,
      default: false,
    },

    /**
     * The title of the dialog.
     *
     * @type {String}
     * @default ''
     */
    title: {
      type: String,
      default: '',
    },

    /**
     * The icon of the dialog.
     *
     * @type {String}
     * @default ''
     */
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      /**
       * The close icon for the dialog.
       *
       * @type {Object}
       */
      mdiClose,

      /**
       * Flag indicating whether the dialog is open or not.
       *
       * @type {Boolean}
       */
      dialog: false,
    };
  },
  watch: {
    /**
     * Watcher for the dialog property.
     *
     * @param {Boolean} newValue The new value of the dialog property.
     */
    dialog: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('change', newValue);
      },
    },
  },
  methods: {
    /**
     * Closes the dialog.
     */
    close() {
      this.dialog = false;
    },

    /**
     * Opens the dialog.
     */
    open() {
      this.dialog = true;
    },
  },
};
</script>
