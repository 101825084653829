import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('packingListItem', 'packingListItems');

const customActions = {
  async LOAD_packingListItems({ commit, rootGetters }, packingListId) {
    const data = await odata.o(rootGetters)
      .get('packingListItems')
      .query({ $filter: `IsDeleted eq false AND PackingListId eq ${packingListId}` });
    commit('SET_packingListItems', data);

    return data;
  },
};

export default { ...actions, ...customActions };
