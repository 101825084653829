export default {
  error: undefined,
  loading: false,
  token: undefined,
  refresh_token: undefined,
  token_expires_in: undefined,
  token_expires: undefined,
  username: undefined,
  userId: undefined,
  roles: [],
  routes: [],
  tables: [],
  claims: [],
  isSaving: false,
  favouritesOnly: false,
  previewFeatures: false,
  productsHeader: [
    {
      order: 1, value: 'imageUrl', text: 'Image Url', filterable: false, width: '50px',
    },
    {
      order: 2, value: 'model', text: 'Model', filterable: true,
    },
    {
      order: 3, value: 'shortDescription', text: 'Short Description', filterable: true,
    },
    {
      order: 4, value: 'unitBarcode', filterable: true, text: 'Unit Barcode',
    },
    {
      order: 5, value: 'actions', filterable: false, text: '',
    },
  ],
  languages: [
    'Arabic',
    'Bengali',
    'Chinese',
    'Danish',
    'Dutch',
    'English',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hebrew',
    'Hindi',
    'Indonesian',
    'Italian',
    'Japanese',
    'Korean',
    'Malay',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Romanian',
    'Russian',
    'Spanish',
    'Swahili',
    'Swedish',
    'Tagalog',
    'Thai',
    'Turkish',
    'Urdu',
    'Vietnamese',
  ],
  countries: [{
    name: 'South Africa',
    approvals: [
      {
        type: 'MD',
        checked: false,
        requested: false,
      },
      {
        type: 'Regulatory',
        checked: false,
        requested: false,
      },
      {
        type: 'RA Marketing Material',
        checked: false,
        requested: false,
      },
    ],
  }, {
    name: 'United Kingdom',
    approvals: [
      {
        type: 'MD',
        checked: false,
        requested: false,
      },
      {
        type: 'Regulatory',
        checked: false,
        requested: false,
      },
      {
        type: 'RA Marketing Material',
        checked: false,
        requested: false,
      },
    ],
  },
  {
    name: 'Europe',
    approvals: [
      {
        type: 'MD',
        checked: false,
        requested: false,
      },
      {
        type: 'Regulatory',
        checked: false,
        requested: false,
      },
      {
        type: 'RA Marketing Material',
        checked: false,
        requested: false,
      },
    ],
  },
  {
    name: 'Australia',
    approvals: [
      {
        type: 'MD',
        checked: false,
        requested: false,
      },
      {
        type: 'Regulatory',
        checked: false,
        requested: false,
      },
      {
        type: 'RA Marketing Material',
        checked: false,
        requested: false,
      },
    ],
  },
  ],
  incoterms: [
    {
      abbreviation: 'CFR',
      description: 'Cost and Freight: Seller pays for the cost of goods and freight to the named destination port.',
    },
    {
      abbreviation: 'CIF',
      description: 'Cost, Insurance, and Freight: Seller pays for the cost, insurance, and freight to the named destination port.',
    },
    {
      abbreviation: 'CIP',
      description: 'Carriage and Insurance Paid To: Similar to CPT, but includes insurance coverage.',
    },
    {
      abbreviation: 'CPT',
      description: 'Carriage Paid To: Seller pays for the carriage of goods to the named destination, but risk transfers at that point.',
    },
    {
      abbreviation: 'DAP',
      description: 'Delivered at Place: Seller delivers the goods to a named place at the destination, ready for unloading.',
    },
    {
      abbreviation: 'DAT',
      description: 'Delivered at Terminal: Seller delivers the goods, unloaded, at a named terminal at the destination.',
    },
    {
      abbreviation: 'DDP',
      description: 'Delivered Duty Paid: Seller is responsible for delivering the goods to the named destination, cleared for import.',
    },
    {
      abbreviation: 'EXW',
      description: "Ex Works: Seller's responsibility ends when the goods are made available at their premises.",
    },
    {
      abbreviation: 'FAS',
      description: 'Free Alongside Ship: Seller is responsible until the goods are placed alongside the ship at the port of shipment.',
    },
    {
      abbreviation: 'FCA',
      description: 'Free Carrier: Seller delivers the goods to a named carrier at an agreed-upon location.',
    },
    {
      abbreviation: 'FOB',
      description: "Free On Board: Seller's responsibility ends when the goods are loaded onto the vessel at the port of shipment.",
    },
  ],
};
