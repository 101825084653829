import Vue from 'vue';
import VueRouter from 'vue-router';
import guards from './guards';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'products',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "route-Dashboard" */'@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/init',
    name: 'app-Init',
    component: () => import(/* webpackChunkName: "route-Init" */'@/views/Init.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/on-board',
    name: 'app-Onboard',
    component: () => import(/* webpackChunkName: "route-OnBoard" */'@/views/Onboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/view/product',
    name: 'products-ViewProduct',
    component: () => import(/* webpackChunkName: "route-ViewProduct" */'@/views/products/ViewProduct.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/event-logs',
    name: 'admin-EventLogs',
    component: () => import(/* webpackChunkName: "route-Unleashed" */'@/views/admin/EventLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/unleashed',
    name: 'admin-Unleashed',
    component: () => import(/* webpackChunkName: "route-Unleashed" */'@/views/admin/Unleashed.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/productPrices',
    name: 'admin-ProductPrices',
    component: () => import(/* webpackChunkName: "route-Unleashed" */'@/views/admin/ListProductPrices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/mail-settings',
    name: 'admin-MailSettings',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/admin/MailSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/import-images',
    name: 'admin-ImageImport',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/admin/ImageImport.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/global-settings',
    name: 'admin-GlobalSettings',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/admin/GlobalSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/product',
    name: 'products-EditProduct',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/products/EditProduct.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/products',
    name: 'products-ListProduct',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/products/ListProduct.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sales/trading-sheet',
    name: 'sales-SalesTradingSheet',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/sales/SalesTradingSheet.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logistics/label-creator',
    name: 'logistics-LabelCreator',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/logistics/LabelCreator.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logistics/packing-list-creator',
    name: 'logistics-PackingListCreator',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/logistics/PackingListCreator.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logistics/packing-lists',
    name: 'logistics-ListPackingList',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/logistics/ListPackingList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/brands',
    name: 'brands-ListBrand',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/brands/ListBrand.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/brand',
    name: 'brands-EditBrand',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/brands/EditBrand.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/currency',
    name: 'admin-ListCurrencyItem',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/admin/ListCurrencyItem.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/feedback',
    name: 'admin-ListFeedbackItems',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/admin/FeedbackItems.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/currencyItem',
    name: 'admin-EditCurrencyItem',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/admin/EditCurrencyItem.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/medical-device-risk-classes',
    name: 'admin-ListMedicalDeviceRiskClass',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/products/ListMedicalDeviceRiskClass.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/medical-device-risk-class',
    name: 'admin-EditMedicalDeviceRiskClass',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/products/EditMedicalDeviceRiskClass.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pallets',
    name: 'admin-ListPallet',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/products/ListPallet.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pallet',
    name: 'admin-EditPallet',
    component: () => import(/* webpackChunkName: "route-General" */'@/views/products/EditPallet.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/templates',
    name: 'admin-ListDocumentTemplate',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/admin/Templates.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/documentTemplate',
    name: 'admin-EditDocumentTemplate',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/admin/EditDocumentTemplate.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/tariffCodes',
    name: 'admin-ListTariffCode',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/products/ListTariffCode.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/tariffCode',
    name: 'admin-EditTariffCode',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/products/EditTariffCode.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/suppliers',
    name: 'supplier-ListSupplier',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/products/ListSupplier.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: {
        item: 'Suppliers',
        parent: 'dashboard',
      },
    },
  },
  {
    path: '/supplier',
    name: 'supplier-EditSupplier',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/products/EditSupplier.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/cartonSymbols',
    name: 'admin-ListCartonSymbol',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/products/ListCartonSymbol.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/cartonSymbol',
    name: 'admin-EditCartonSymbol',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/products/EditCartonSymbol.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/product-groups',
    name: 'product-groups-ListProductGroup',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/product-groups/ListProductGroup.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/product-group',
    name: 'product-groups-EditProductGroup',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/product-groups/EditProductGroup.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/users',
    name: 'admin-Users',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/UserManagementHome.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/orgs',
    name: 'admin-ListOrg',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/admin/ListOrg.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import(/* webpackChunkName: "route-Admin" */'@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import(/* webpackChunkName: "route-Login" */'@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    component: () => import(/* webpackChunkName: "route-Register" */'@/views/auth/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import(/* webpackChunkName: "route-Error" */'@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(guards);

export default router;
