import dayjs from 'dayjs';

export const useFormatting = () => {
  const formatDate = (date, format = 'YYYY-MM-DD') => dayjs(date).format(format);
  const formatDateTime = (date, format = 'YYYY-MM-DD HH:mm:ss') => dayjs(date).format(format);
  const formatDateTimeSeconds = (date, format = 'YYYY-MM-DD HH:mm:ss') => dayjs(date).format(format);

  return {
    formatDate,
    formatDateTime,
    formatDateTimeSeconds,
  };
};
