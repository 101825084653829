import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('productPriceBreak', 'productPriceBreaks');

const customActions = {
  async LOAD_productPriceBreaks({ commit, rootGetters }, localProductPurchasePriceId) {
    const data = await odata.o(rootGetters)
      .get('productPriceBreaks')
      .query({
        $filter: `IsDeleted eq false AND ProductPurchasePriceId eq ${localProductPurchasePriceId}`,
        $orderby: 'Created desc',
      });
    commit('SET_productPriceBreaks', data);

    return data;
  },
};

export default { ...actions, ...customActions };
