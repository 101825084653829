/* eslint-disable camelcase */
const qs = require('qs');

export default {
  INIT_auth({ commit }, userOptions) {
    const defaults = {
      client_id: '',
      client_secret: '',
    };
    const options = Object.assign(defaults, userOptions);
    if (options.url.length === 0) {
      throw options;
    }
    commit('SET_options', options);
  },
  async LOGIN_auth({ commit, state }, data) {
    const { username, password } = data;
    const { client_id, client_secret } = state.options;
    const result = await this.$http.post('/token', qs.stringify({
      username,
      password,
      client_id,
      client_secret,
      grant_type: 'password',
    }));

    commit('SET_token', result.data.access_token);
    commit('SET_username', result.data.userName);
    commit('SET_roles', result.data.roles ?? '');
    commit('SET_refresh_token', result.data.refresh_token);
    commit('SET_token_expires', result.data['.expires']);
    commit('SET_token_expires_in', result.data.expires_in);
    commit('SET_userId', result.data.userId);

    return result.data;
  },
  // eslint-disable-next-line no-unused-vars
  async REGISTER_auth({ state }, data) {
    const { username, email, password } = data;
    const result = await this.$http.post('/api/account/register', qs.stringify({
      Username: username,
      Email: email,
      Password: password,
    }));

    return result;
  },
  async REFRESH_auth({ commit, state }) {
    // REFRESH_auth started
    // eslint-disable-next-line no-unused-vars
    const { token_expires, last_token_refresh_request } = state;

    // const date1 = new Date(token_expires);
    const date2 = new Date();

    // const expires = Math.abs(date2 - date1);
    const lastRefresh = Math.abs(new Date(last_token_refresh_request) - date2);

    // check if token expires in the next 10 minutes
    // and no other request was made in the last 3 seconds
    if (lastRefresh > 5000) {
      const { client_id, client_secret } = state.options;
      const { refresh_token } = state;
      const result = await this.$http.post('/token', qs.stringify({
        grant_type: 'refresh_token',
        refresh_token,
        client_id,
        client_secret,
      }));

      commit('SET_token', result.data.access_token);
      commit('SET_refresh_token', result.data.refresh_token);
      commit('SET_token_expires', result.data['.expires']);
      commit('SET_token_expires_in', result.data.expires_in);
      commit('SET_last_token_refresh_request', new Date());
    }
  },
  LOGOUT_auth({ commit }) {
    commit('SET_token', undefined);
    commit('SET_username', undefined);
    commit('SET_roles', '');
    commit('SET_refresh_token', undefined);
    commit('SET_token_expires', undefined);
    commit('SET_token_expires_in', undefined);
    commit('SET_userId', undefined);
  },
  // eslint-disable-next-line no-unused-vars
  async ADMIN_REGISTER_auth({ commit }, data) {
    // strange quirk where the baseURL is not persisted
    const { username, email, password } = data;
    const result = await this.$http.post('/join/admin-register', {
      phoneNumber: username,
      email,
      password,
    });

    return result;
  },
};
