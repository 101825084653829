import axios from 'axios';

class ODataKeyValidator {
  constructor(entity, key) {
    this.baseUrl = `${process.env.VUE_APP_APIURL}/odata`;
    this.entity = entity;
    this.keyField = key;
  }

  async validate(value) {
    let url = `${this.baseUrl}/${this.entity}?$select=${this.keyField}`;
    url += `&$filter=${this.keyField} eq '${value}'`;

    const { data } = await axios.get(url);

    return data.value?.length === 0;
  }
}

export default ODataKeyValidator;
