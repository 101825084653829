export default {
  SET_error(state, value) {
    state.error = value;
  },
  SET_loading(state, value) {
    state.loading = value;
  },
  SET_token(state, value) {
    state.token = value;
  },
  SET_username(state, value) {
    state.username = value;
  },
  SET_hrdTypeId(state, value) {
    state.hrdTypeId = value;
  },
  SET_appMode(state, value) {
    state.appMode = value;
  },
  SET_refresh_token(state, value) {
    state.refresh_token = value;
  },
  SET_token_expires_in(state, value) {
    state.token_expires_in = value;
  },
  SET_token_expires(state, value) {
    state.token_expires = value;
  },
  SET_userId(state, value) {
    state.userId = value;
  },
  ADD_table(state, value) {
    state.tables.push(value);
  },
  SET_table(state, value) {
    const index = state.tables.findIndex(x => x.name === value.name);
    state.tables[index] = value;
  },
  SET_tables(state, value) {
    state.tables = value;
  },
  SET_isSaving(state, value) {
    state.isSaving = value;
  },
  SET_routes(state, value) {
    state.routes = value;
  },
  SET_roles(state, value) {
    if (value) {
      state.roles = value?.split(',');
    } else {
      state.roles = [];
    }
  },
  SET_favouritesOnly(state, value) {
    state.favouritesOnly = value;
  },
  SET_productsHeader(state, value) {
    state.productsHeader = value;
  },
  SET_claims(state, value) {
    state.claims = value;
  },
  SET_previewFeatures(state, value) {
    state.previewFeatures = value;
  },
  TOGGLE_previewFeatures(state) {
    state.previewFeatures = !state.previewFeatures;
  },
};
