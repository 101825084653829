import { odata } from '../../conf';
import Actions from '../../odata/actionsForStringId';

const actions = new Actions('tariffCode', 'tariffCodes', 'code');

const customActions = {
  async LOAD_tariffCodes({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('tariffCodes')
      .query({
        $filter: 'IsDeleted eq false',
        $orderby: 'Code',
      });
    commit('SET_tariffCodes', data);

    return data;
  },
};

export default { ...actions, ...customActions };
