/**
 * Represents a filter field for data tables.
 */
export default class {
  /**
   * Creates a new instance of the FilterField class.
   * @param {string} field - The field to filter on.
   * @param {string} option - The filter option to apply.
   * @param {string} [value=''] - The value to filter by.
   */
  constructor(field, option, value = '') {
    this.field = field;
    this.option = option;
    this.value = value;
  }

  /**
   * Generates the query string for the filter field.
   * @returns {string} The query string.
   */
  query() {
    const { field, option, value } = this;

    return `${field} ${option} ${value}`;
  }
}
